import React from "react";
import { useTranslation } from "react-i18next";

const OtherPage = () => {
    const { t } = useTranslation();
    return (
        <div>
            <p>{t("otherPage.descriptionIntruction")}</p>
            <span>{t("otherPage.step1")}</span>
            <p></p>
            <span>{t("otherPage.step2")}</span>
        </div>
    );
};

export default OtherPage;