import React from 'react';
import PropTypes from "prop-types";

const Overlay = ({hidden, zIndex, onClick}) => {
    return (
        <div className={"overlay"} style={{display: hidden ? "none" : "block", zIndex: zIndex}} onClick={onClick}>
        </div>
    );
};

Overlay.propTypes = {
    hidden: PropTypes.bool,
    zIndex: PropTypes.number,
    onClick: PropTypes.func
};

export default Overlay;