import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../services/auth";

export const getConfig = createAsyncThunk(
    "auth/getConfig",
    async () => {
        try {
            const resp = await AuthService.getConfig();
            return resp.data;
        } catch (e) {
            // console.log("Has error when get OTP", e.getMessage);
            return e;
        }
    }
);

export const getOTP = createAsyncThunk(
    "auth/getOTP",
    async (data) => {
        try {
            const resp = await AuthService.getOTP({ phoneNumber: data.phoneNumber });
            resp.data.inforPage1 = data.saveInforPage1;
            return resp.data;
        } catch (e) {
            // console.log("Has error when get OTP", e);
            return e;
        }
    }
);



export const verifyOTP = createAsyncThunk(
    "auth/veryfiOTP",
    async (data) => {
        try {

            const dataVerify = {
                phoneNumber: data.phoneNumber,
                otp: data.otp,
                productCode: data.saveInforPage1?.product,
                agreedToPolicy: data.checkCodition,
                dateOfBirth: data.saveInforPage1?.dateOfBirth,
                gender: data.saveInforPage1?.gender ? 1 : 2
            };
            const resp = await AuthService.verifyOTP(dataVerify);
            resp.data.inforPage1 = data.saveInforPage1;
            return resp.data;
        } catch (e) {
            // console.log("Has error when verify OTP", e.getMessage);
            return e;
        }
    }
);

export const logOut = createAsyncThunk(
    "auth/logOut",
    async (data) => {
        try {
            const resp = await AuthService.logOut();
            return resp.data;
        } catch (e) {
            // console.log("Has error when verify OTP", e.getMessage);
            return e;
        }
    }
);

export const resetErrorPopupAuth = createAction("main/resetErrorPopupAuth", (data) => {
    return {
        payload: {
            data,
        },
    };
});
