import './App.css';
import React, {Component} from 'react';
import ProtectedLayout from "./views/layouts";

class App extends Component {
    render() {
        return (
            <ProtectedLayout/>
        );
    }
}


export default App;
