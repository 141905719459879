import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Interest_Rates } from "../../../assets/icons";
import { NOTE_CONTENT, revertFormatCurrency } from "../../../utils";
import NoteIcon from "../../components/icon/noteIcon";
import { useForm, useWatch } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "../../components/input/input";
import { useDispatch, useSelector } from "react-redux";
import {
    calculateByInterestRate,
    resetBackToPage2,
    resetGotoPage1,
    saveInputValuePage2
} from "../../../features/main/mainAction";
import CalculateResult from "./calculateResult";
import Footer from "../../components/footer/footer";
import { URL_PAGE } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/popup/popup";

const Page3 = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isHiddenInterestRateNote, setIsHiddenInterestRateNote] = useState(true);

    const [isErrorRateCallThan3, setIsErrorRateCallThan3] = useState(false);

    const schema = yup.object().shape({
        interestRate: yup.string()
            .matches(/\d+(\.\d)?/, "regex1")
            .matches(/^(0\.[5-9]|[1-5](\.\d)?|6(\.0)?)$/, "regex2")
            .required("required")
    });
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            "interestRate": ""
        },
        reValidateMode: "onChange",
        resolver: yupResolver(schema)
    });
    const showOffNotePopup = () => {
        if (!isHiddenInterestRateNote) {
            setIsHiddenInterestRateNote(true);
        }
    };
    const {
        isPopupPage3,
        isCalculatedByInterestRate,
        insuredAmount,
        dateOfBirth,
        gender,
        policyTerm,
        estimateFeePayment,
        moneyTransactionValue,
        isErrorCallCalculateRateThan3,
        numberCallRate,
        isUnAuthorized
    } = useSelector(state => state.main);

    const onSubmit = (data) => {
        // console.log("page 3 data", data);
        // console.log("policyTerm", policyTerm);
        // console.log("estimateFeePayment", estimateFeePayment);

        const requestData = {
            insuredAmount: revertFormatCurrency(insuredAmount),
            dateOfBirth: dateOfBirth,
            gender: gender,
            moneyTransactionValue: revertFormatCurrency(moneyTransactionValue),
            coverageTermPeriod: parseInt(policyTerm),
            paymentTermPeriod: parseInt(estimateFeePayment),
            projectionRate:  (data.interestRate / 100).toFixed(3),
            type: 2
        };
        // console.log(requestData)
        dispatch(calculateByInterestRate(requestData));
    };

    const rateValueChange = useWatch({ control, name: "interestRate" });
    useEffect(() => {
        setIsErrorRateCallThan3(false);
    }, [rateValueChange]);

    useEffect(() => {
        setIsErrorRateCallThan3(isErrorCallCalculateRateThan3);
    }, [isErrorCallCalculateRateThan3, numberCallRate])

    

    const handleBackPage = () => {
        dispatch(resetBackToPage2())
        sessionStorage.setItem("pages", "2");
        navigate(URL_PAGE.PAGE_2);
    };
    const onClosePopUp = () => {
        if (!isUnAuthorized)
        {
            window.location.href = URL_PAGE.RELOAD_PAGE_1
        }
        else
        {
            dispatch(resetGotoPage1());
            sessionStorage.setItem("pages", "1");
            navigate(URL_PAGE.PAGE_1);
        }
    }
    return (
        <>
            <Popup hidden={isUnAuthorized} header={t("popUp.header")} content={t("popUp.contentUnAuthorized")} onClose={onClosePopUp} />
            <Popup hidden={isPopupPage3} header={t("popUp.header")} content={t("popUp.contentErrorSystem")} onClose={onClosePopUp} />
            <div className={"container content"} onClick={showOffNotePopup} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: "100px" }}>
                <section className="section__insurance-money section__interest-rate-levels">
                    <div className="row justify-content-center">
                        <div className="row col-12 col-md-5 col-xl-6 pt-1 justify-content-center">
                            <div className="col-2 col-sm-2 "><img src={Interest_Rates}
                                alt={t("page3.section1.title1")} />
                            </div>
                            <div className="col-8 col-xl-6">
                                <h2 className="function__title ">{t("page3.section1.title1")}</h2>
                            </div>
                            <NoteIcon
                                classNameDivOut={"col-1 col-xl-1 faq-icon pt-2"}
                                classNameNote={"faq-button faq-button-6"}
                                idButton={"faq-button"}
                                target={"popup6"}
                                contentList={NOTE_CONTENT.interestRateNotContent}
                                isHidden={isHiddenInterestRateNote}
                                setIsHidden={setIsHiddenInterestRateNote}
                                error={!!errors.interestRate?.message}
                            />
                        </div>
                        <div className="row col-12 col-md-7 col-xl-6 justify-content-center insurance-money__insert ">
                            <div className=" col-1 col-xs-2 col-xl-2 unit pl-5 pt-1">{t("percent")}</div>
                            <div className=" col-10 number_money form-outline">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="number-input col-5 col-md-5">
                                            <Input
                                                className={"form-control number-percent-input"}
                                                control={control}
                                                name={"interestRate"}
                                                placeholder={"0.0"}
                                                error={!!errors.interestRate?.message}
                                            />
                                        </div>
                                        <div className="col-6 col-md-5  calculate-icon">
                                            <a href="#" id="page3-calculate">
                                                <button type={"submit"} className="page3-calculate btn">TÍNH TOÁN
                                                </button>
                                            </a>
                                        </div>
                                        <div>
                                            {isErrorRateCallThan3 && <span className="error-message-2" style={{ display: isErrorRateCallThan3 ? "block" : "none" , marginTop:"15px"}}>{t("page3.errorCallGreaterThan3")}</span>}
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {isCalculatedByInterestRate && <CalculateResult />}

            </div>
            <Footer page={3} isEndProcess={isCalculatedByInterestRate} enableContinue={isCalculatedByInterestRate} className={"fixed-bottom"}  onclickBackProcess={handleBackPage} />
        </>

    );
};

export default Page3;