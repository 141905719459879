import i18n from "i18next";
import enTrans from "./en.json";
import vnTrans from "./vn.json";
import { initReactI18next } from "react-i18next";

const resources = {
    en: { translation: enTrans },
    vn: { translation: vnTrans }
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng: "vn",
        fallbackLng: "vn",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;