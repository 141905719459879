import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Gender_Man, Gender_Woman } from "../../../assets/icons";
// import DateInput from "../../components/dateInput/dateInput";
import { calculateYearsAge, calculateDaysAge, PLACE_HOLDER } from "../../../utils";
import cn from "classnames";
import Section5 from "./section5";
// import Select from "../../components/select/select";
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../components/input/input';
import { getOTP, resetErrorPopupAuth } from "../../../features/auth/authAction";
import Section5NoOtp from "./section5NoOtp";
import DateInput from "../../components/dateInput/dateinputv2";
import Popup from "../../components/popup/popup";
import { URL_PAGE } from "../../../utils/constant";
import TimerCount from "../../components/timerCount/timerCount";
import { useNavigate } from "react-router-dom";


const Page1 = () => {
    sessionStorage.setItem("pages", "1");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const productList = [
        {
            "value": "DEFAULT",
            "label": "Chọn sản phẩm chính"
        },
        {
            "value": "ULD",
            "label": "Quà tặng sức khỏe"
        }
    ];
    const { getOTPRes,
        inforPage1,
        isExistSession,
        isPopup,
        isSendOTP,
        timerCountDownSendOtp,
        isVerifyOtp,
        isLockSendOtp,
        numberSendOtp,
        isErrorNotfound,
        isUnAuthorizedVerify
    } = useSelector((state) => state.auth);
    const [dob, setDob] = useState(!!inforPage1 ? inforPage1.dateOfBirth : "");
    const [dobError, setDobError] = useState("");

    const [proValue, setProcValue] = useState(!!inforPage1 ? inforPage1.product : "DEFAULT");
    const [proError, setProError] = useState("");

    const [secondCountDown, setSecondCountDown] = useState(-1);
    const [isErrorNotFoundOr500, setIsErrorNotFoundOr500] = useState(false);
    const [isErrorSendOtp, setIsErrorSendOtp] = useState(false);
    const [isBlockGetOtp, setIsBlockGetOtp] = useState(false);

    const [isSelectedMale, setIsSelectedMale] = useState(inforPage1?.gender === "1" ? true : false);
    const [isSelectedFemale, setIsSelectedFemale] = useState(inforPage1?.gender === "2" ? true : false);
    const [isChangeButton, setIsChangeButton] = useState(false);
    const [oldPhoneNumberValue, setOldPhoneNumberValue] = useState(!!inforPage1 ? inforPage1.phoneNumber : "");
    const dispatch = useDispatch();

    const schemeForm1 = yup.object().shape({
        // dateOfBirth: yup.string()
        //     .test("Greate_than_65_year", t("page1.section1.errors.notHasProduct"), (val) => {
        //         if (new Date(val).getFullYear() < new Date().getFullYear()) {
        //             let yearsAge = calculateYearsAge(val);
        //             return (yearsAge < 65)
        //         }
        //         else {
        //             let daysAge = calculateDaysAge(val);
        //             return (daysAge > 30)
        //         }
        //     }),
        phoneNumber: yup.string()
        .min(10, "less than 10")
        .max(15, "greate than 15")
        .matches(/[0-9]/, "valid parten")
        .test("validDOB","DOB null", (val)=>{
                if(dob === "")
                {
                    setDobError("  ");
                }
                return true;
        })
        .required(),
        // product: yup.string().test("notChoose", "Not choose product", (val) => { console.log(val); return val !== "DEFAULT" }),
        gender: yup.string()
            .test(
                "notChoose",
                "Not choose gender",
                (val) => {
                    if (val === "") {
                        if(dob === "")
                        {
                            setDobError("  ");
                        }
                         return false;
                    } else {
                        return true;
                    }
                }
            )
    });

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
        defaultValues: {
            product: !!inforPage1 ? inforPage1.product : "DEFAULT",
            dateOfBirth: !!inforPage1 ? inforPage1.dateOfBirth : "",
            gender: !!inforPage1 ? inforPage1.gender : "",
            phoneNumber: !!inforPage1 ? inforPage1.phoneNumber : "",
        },
        reValidateMode: "onChange",
        resolver: yupResolver(schemeForm1)
    });

    // console.log(errors);
    // console.log(getValues());

   

    const onTextChange = useCallback((evt) => {
        const dobValue = evt.target.value;
        if (dobValue === "") {
            setDobError("   ");
        }
        else {
            if (new Date(dobValue).getFullYear() < new Date().getFullYear()) {
                const yearsAge = calculateYearsAge(dobValue);
                if (yearsAge > 65) {

                    setDobError(t("page1.section1.errors.notHasProduct"));
                } else {
                    setDobError("");
                }
            } else {
                const daysAge = calculateDaysAge(dobValue);

                if (daysAge < 30) {
                    setDobError(t("page1.section1.errors.notHasProduct"));
                } else {
                    setDobError("");
                }
            }

            setValue("dateOfBirth", evt.target.value);
        }
        setDob(evt.target.value);
    }, []);

    const onChangeSelectProc = (e) => {
        const product = e.target.value;
        setProError("");
        if (product === "DEFAULT") {
            setProError("  ");
        }
        setProcValue(product);
        setValue("product", product);
    }

    useEffect(() => {
        setIsChangeButton(isSendOTP);
        setIsErrorSendOtp(!isSendOTP);
        setIsErrorNotFoundOr500(isErrorNotfound);
        if (timerCountDownSendOtp > 0 && isLockSendOtp) {
            setIsBlockGetOtp(isLockSendOtp);
            setSecondCountDown(timerCountDownSendOtp);
        }
    }, [isSendOTP, timerCountDownSendOtp, isLockSendOtp,numberSendOtp,isErrorNotfound]);

    useEffect(() => {
        if (secondCountDown === 0) {
            setIsBlockGetOtp(false);
            setIsErrorSendOtp(false);
        }
    }, [secondCountDown])

    const isChangePhoneNumber = useWatch({ control, name: "phoneNumber" });
    useEffect(() => {
        if (isChangePhoneNumber !== oldPhoneNumberValue && !!isChangePhoneNumber) {
            setOldPhoneNumberValue(isChangePhoneNumber);
            setIsErrorNotFoundOr500(false);
            setIsErrorSendOtp(false);
            setIsChangeButton(false);
        }
    }, [isChangePhoneNumber, oldPhoneNumberValue])

    const onSelectGenderMale = () => {
        setIsSelectedMale(true);
        setIsSelectedFemale(false);
        setValue("gender", "1");
    }
    const onSelectGenderFemale = () => {
        setIsSelectedFemale(true);
        setIsSelectedMale(false);
        setValue("gender", "2");

    }

    const getOtpNumber = (data) => {
        if (dob !== "" && proValue !== "DEFAULT") {
            const sendOTP = {
                phoneNumber: data.phoneNumber,
                saveInforPage1: {
                    dateOfBirth: data.dateOfBirth,
                    gender: data.gender,
                    product: data.product,
                    phoneNumber: data.phoneNumber,
                }
            }
            dispatch(getOTP(sendOTP));
        } else {
            if(dob === ""){
                setDobError("  ");
            }else if (proValue === "DEFAULT")
            {
                setProError("  ");
            }
            
        }
    }

    const onClosePopUp = () => {
        if(!isUnAuthorizedVerify)
        {
            window.location.href = URL_PAGE.RELOAD_PAGE_1
        }
        else
        {
            dispatch(resetErrorPopupAuth());
            sessionStorage.setItem("pages", "1");
            navigate(URL_PAGE.PAGE_1);
        }
        
    }

    
    return (
        <>
            <Popup hidden={isUnAuthorizedVerify} header={t("popUp.header")} content={t("popUp.contentUnAuthorized")} onClose={onClosePopUp} />
            <Popup hidden={isPopup} header={t("popUp.header")} content={t("popUp.contentErrorSystem")} onClose={onClosePopUp} />

            {/* <div className={"container content content-p1"}> */}
                <section className="section">
                    <div className="row justify-content-center">
                        <div className="col-md-7 ">
                            <h2 className="section__title text-center">{t("page1.section1.mainTitle")}</h2>
                            <DateInput
                                id={"dateOfBirth"}
                                className={"form-control"}
                                placeholder={PLACE_HOLDER.DATE_INPUT}
                                error={dobError}
                                value={dob}
                                onTextChange={onTextChange} />
                        </div>
                    </div>
                </section>
                {/*  section 1 - năm sinh start  */}
                <form onSubmit={handleSubmit(getOtpNumber)}>
                    {/* <section className="section">
                        <div className="row justify-content-center">
                            <div className="col-md-7 ">
                                <h2 className="section__title text-center">{t("page1.section1.mainTitle")}</h2>
                                <DateInput className={"form-control"}
                                    placeholder={PLACE_HOLDER.DATE_INPUT}
                                    error={errors?.dateOfBirth?.message}
                                    name={"dateOfBirth"}
                                    control={control}
                                    id={"dateOfBirth"}
                                />
                            </div>
                        </div>
                    </section> */}
                    {/*  section 1 - năm sinh end  */}

                    {/*  section 2 - giới tính start  */}
                    <Input control={control} name="dateOfBirth" type={"hidden"} />
                    <section className="section">
                        <div className="row justify-content-center">
                            <div className=" col-md-6 .col-lg-6">
                                <h2 className="section__title text-center ">{t("page1.section2.mainTitle")}</h2>
                                <div className="section__choose-gender row pl-3">
                                    <div className="row col-12 center-content ">
                                        <div className={errors?.gender && !isSelectedMale && !isSelectedFemale ? "col-3 section__choose-gender__wrapper_errors" : "col-3 section__choose-gender__wrapper"} >
                                            <div
                                                className={cn("center-content section__choose-gender--effect", { "selected-effect": isSelectedMale })}
                                                data-gender="male" control={control} onClick={onSelectGenderMale} name={"gender"}>
                                                <div className="section__choose-gender__box py-3 px-3">
                                                    <img src={Gender_Man} alt="Nam" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-3"></div>
                                        <div className={errors?.gender && !isSelectedMale && !isSelectedFemale ? "col-3 section__choose-gender__wrapper_errors" : "col-3 section__choose-gender__wrapper"} >
                                            <div
                                                className={cn("center-content section__choose-gender--effect", { "selected-effect": isSelectedFemale })}
                                                data-gender="female" onClick={onSelectGenderFemale} control={control} name={"gender"}>
                                                <div className="section__choose-gender__box py-3 px-3" >
                                                    <img src={Gender_Woman} alt="Nữ" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*  section 2 - giới tính end  */}

                    {/*  section 3 - chọn sản phẩm chính start  */}
                    <section className="section">
                        <div className="row justify-content-center">
                            <div className="col-md-6 ">
                                <h2 className="section__title text-center">{t("page1.section3.mainTitle")}</h2>
                                <div className="section__select-form container mt-4 d-flex justify-content-center" style={{ pointerEvents: dobError?.length > 0 ? "none" : null }}>
                                    {/* <Select
                                        className={"form-select form-select-lg mb-3"}
                                        control={control}
                                        name={"product"}
                                        options={productList}
                                        style={{ borderColor: errors?.product || isErrorProcduct ? "red" : "" }}
                                    /> */}
                                    <select
                                        className={"form-select form-select-lg mb-3"}
                                        value={proValue}
                                        onChange={onChangeSelectProc}
                                        style={{ borderColor: proError?.length > 0? "red" : "" }}
                                    >
                                        {productList.map((item) => (
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                    <Input control={control} name="product" type={"hidden"} />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*  section 3 - chọn sản phẩm chính end  */}

                    {/*  section 4 - số điện thoại và OTP start  */}
                    <section className="section-phone pb-2 phone_otp" style={{ display: dobError?.length > 0 ? "none" : "block" }}>
                        <div className="row justify-content-center">
                            <div className="col-md-6 "> {/* start**/}
                                <h2 className="section__title text-center">{t("page1.section4.mainTitle")}</h2>
                                <div className="section__phone-input container mt-2 d-flex justify-content-center">
                                    <div className="container justify-content-center row">
                                        <div id="phoneInputSection" className="row ">
                                            <div className="col-7 col-md-8">
                                                <Input className={"form-control mb-2"}
                                                    id="phoneNumber"
                                                    name={"phoneNumber"}
                                                    placeholder={t("page1.section4.placeHolder.phoneNumber")}
                                                    control={control}
                                                    error={!!errors.phoneNumber?.message}
                                                    readOnly={isVerifyOtp || isBlockGetOtp ? true : false}
                                                    style={{ color: isVerifyOtp ? "#495057" : null }} />
                                            </div>
                                            <div className="col-3">
                                                <button
                                                    id="sendOTPButton"
                                                    className={isChangeButton || isBlockGetOtp || isVerifyOtp ? "btn btn-otp disabled" : "btn btn-otp"}
                                                    type="submit">GỬI</button>
                                            </div>
                                            <span className="error-message-2" style={{ display: isErrorSendOtp || isErrorNotFoundOr500 ? 'block' : 'none' }}>{getOTPRes?.code === "500" ? t(`page1.section4.errors.${getOTPRes?.code}`) : getOTPRes?.message}</span>

                                        </div>
                                        {/* {(isBlockGetOtp) && <div id="countdown" className="countdown">{countDownMin}:{countDownSecond} s</div>} */}
                                        {(isBlockGetOtp) && <TimerCount timerCount={secondCountDown} setInitialTime={setSecondCountDown} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            {/* </div> */}
            {/**Section 5 + footer*/}

            {
                ((isExistSession && isChangeButton) || (!isExistSession && !isChangeButton) )?
                    <Section5NoOtp
                        errorForm={errors}
                        procError={proError}
                        dateInput={dob}
                        dobError={dobError}
                        isChangeButton={isExistSession}
                        dataPage1={getValues()}
                        setProcError={setProError}
                    />
                    :
                    <Section5
                        dobError={dobError}
                        procError={proError}
                        isChangeButton={isChangeButton}
                        // secondWatingOtp={timerCountDownSendOtp}
                        setIsChangeButton={setIsChangeButton}
                        errorForm={errors}
                        dataPage1={getValues()}
                        setProcError={setProError}
                    />
            }
        </>

    );
};

export default Page1;