import React from 'react';
import Overlay from "../overlay/overlay";

const Popup = ({hidden, header, content, onClose}) => {
    return (
        <div className={"popupWrapper"} style={{display:hidden?'none':'block'}}>
            <Overlay zIndex={3000} hidden={hidden}/>
            <div className={"container"}>
                <h3 className={"header"}>{header}</h3>
                <div className={"content"}>{content}</div>
                <div className={"btnContainer"}>
                    <button className={"confirmBtn"} type={"button"} onClick={onClose}>ĐỒNG Ý</button>
                </div>
            </div>
        </div>
    );
};

export default Popup;