import React from "react";
import {useController} from "react-hook-form";
import PropTypes from "prop-types";

const CheckBox = ({className, control, name, ...props}) => {
    const {field} = useController({
        control,
        name
    });
    return (
        <input
            type="checkbox"
            className={className}
            name={field.name}
            value={field.value}
            ref={field.ref}
            checked={field.value}
            onChange={(e) => {
                field.onChange(e.target.checked);
                // console.log("checkbox value", e.target.checked);
            }}
            {...props}
        />
    );
};

CheckBox.propTypes = {
    className: PropTypes.string
}

export default CheckBox