import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/footer/footer';
import { useForm, useWatch } from "react-hook-form";
import Input from '../../components/input/input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOTP } from '../../../features/auth/authAction';
import CheckBox from '../../components/input/checkBox';
import { useNavigate } from "react-router-dom";
import { URL_PAGE } from '../../../utils/constant';
import TimerCount from '../../components/timerCount/timerCount';

const Section5 = ({ dobError, procError, isChangeButton, setIsChangeButton, errorForm, dataPage1, setProcError }) => {
    // console.log("section 5 otp");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [secondCountDownGetOtp, setSecondCountDownGetOtp] = useState(-1);
    const [secondCountDownErrorVerify, setSecondCountDownErrorVerify] = useState(-1);
    const [readOnlyOtp, setReadOnlyOtp] = useState(false);
    const [isErrorVerifyOtp, setIsErrorVerifyOtp] = useState(false);
    const { verifyOtpRes,
        inforPage1,
        isReadOnly,
        isVerifyOtp,
        timerCountDownVerifyOtp, timerCountDownSendOtp
    } = useSelector((state) => state.auth);

    const schemaInputSection5 = yup.object().shape({
        otp: yup.string().max(6, "greate than 6").matches(/[0-9]/, "valid parten").required()
    });

    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            otp: !!inforPage1 && inforPage1?.otp ? inforPage1?.otp : "",
            checkCodition: !!inforPage1 && inforPage1?.checkCodition ? inforPage1.checkCodition : false
        },
        resolver: yupResolver(schemaInputSection5)
    });

    const enableContinue = useWatch({ control, name: "checkCodition" });

    useEffect(() => {
        setIsErrorVerifyOtp(!isVerifyOtp);
        setReadOnlyOtp(isReadOnly);
    }, [isReadOnly, isVerifyOtp]);

    useEffect(() => {
        if (isChangeButton) {
            if (!isVerifyOtp && timerCountDownVerifyOtp > 0) {
                setSecondCountDownErrorVerify(timerCountDownVerifyOtp);
                setSecondCountDownGetOtp(-1);

            } else {
                setSecondCountDownGetOtp(timerCountDownSendOtp);
                setSecondCountDownErrorVerify(-1);

            }
        }
    }, [timerCountDownSendOtp,
        isChangeButton,
        timerCountDownVerifyOtp,
        isVerifyOtp]);
    useEffect(() => {
        if (secondCountDownGetOtp === 0 && timerCountDownSendOtp > 0) {
            setIsChangeButton(false);
        }
        if (secondCountDownErrorVerify === 0 && timerCountDownVerifyOtp > 0) {
            setReadOnlyOtp(false);
            setIsChangeButton(false);
            setIsErrorVerifyOtp(false);
            setValue("otp", "");
        }

    }, [secondCountDownGetOtp,
        setValue,
        setIsChangeButton,
        secondCountDownErrorVerify,
        timerCountDownSendOtp,
        timerCountDownVerifyOtp
    ])

    // useEffect(() => {
    //     let myInterval = setInterval(() => {
    //         if (countDownSecond > 0) {
    //             setCountDownSecond(countDownSecond - 1);
    //         }
    //         if (countDownSecond === 0 && countDownMin > 0) {
    //             setCountDownMin(countDownMin - 1);
    //             setCountDownSecond(59);
    //         }
    //         if (countDownSecond === 0 && countDownMin === 0) {
    //             setReadOnlyOtp(false);
    //             setIsChangeButton(false);
    //             setIsErrorVerifyOtp(false);
    //             setValue("otp", "");
    //             setCountDownMin(null);
    //             setCountDownSecond(null);
    //         }
    //     }, 1000)
    //     return () => {
    //         clearInterval(myInterval);
    //     };
    // });

    const onSubmitSection5 = (data) => {
        if (dataPage1.product === "DEFAULT") {
            setProcError("  ");
        }
        else {
            let datasend = {
                checkCodition: data.checkCodition,
                phoneNumber: inforPage1.phoneNumber,
                otp: data.otp,
                saveInforPage1: {
                    ...inforPage1,
                    dateOfBirth: dataPage1?.dateOfBirth !== inforPage1?.dadateOfBirth ? dataPage1?.dateOfBirth : inforPage1?.dadateOfBirth,
                    gender: dataPage1?.gender !== inforPage1?.gender ? dataPage1?.gender : inforPage1?.gender,
                    checkCodition: data.checkCodition,
                    otp: data.otp,
                }
            }

            dispatch(verifyOTP(datasend)).then((res) => {
                if (res.payload.code === "000") {
                    sessionStorage.setItem("pages", "2");
                    navigate(URL_PAGE.PAGE_2);
                }
            });
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitSection5)}>
                {/* <div className={"container content content-p1"}> */}
                    <section className="section-phone pb-2 phone_otp" style={{ display: (!!dobError && "" !== dobError) || !isChangeButton ? "none" : "block" }}>
                        <div className="row justify-content-center">
                            <div className="col-md-6 "> {/* start**/}
                                <p className="section__content text-center">{t("page1.section4.subTitle")}</p>
                                <div className="section__show-otp container mt-2 d-flex justify-content-center">
                                    <div className="container justify-content-center row">
                                        <div id="phoneInputSection" className="row justify-content-center">
                                            <Input className={"form-control mb-2"} id="OTP_input" name={"otp"} placeholder={t("page1.section4.placeHolder.Otp")}
                                                control={control} error={!!errors.otp?.message} readOnly={readOnlyOtp} />
                                        </div>
                                        <span className="error-message-2" style={{ display: isErrorVerifyOtp ? "block" : "none" }}>{
                                            verifyOtpRes?.code === "002" ? t(`page1.section4.errors.errorSection4.${verifyOtpRes?.code}`) : verifyOtpRes?.message}
                                        </span>
                                    </div>
                                </div>
                                {/* {(isChangeButton) && <div id="countdown" className="countdown">{countDownMin}:{countDownSecond} s</div>} */}
                                {(isChangeButton && timerCountDownVerifyOtp > 0) ?
                                    <TimerCount timerCount={secondCountDownErrorVerify} setInitialTime={setSecondCountDownErrorVerify} />
                                    :
                                    <TimerCount timerCount={secondCountDownGetOtp} setInitialTime={setSecondCountDownGetOtp} />}
                            </div>
                        </div>
                    </section>
                    {/*  section 4 - số điện thoại và OTP end  */}

                    {/*  section 5 - cam kết start  */}
                    <section className="section" style={{ display: (!!dobError && "" !== dobError) || !isChangeButton ? "none" : "block" }}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8 pl-5 " >
                                <div className="section__privacy-policy align-items-center">
                                    <CheckBox className={"form-check-input"} name={"checkCodition"} control={control} id={"defaultCheck1"} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">{t("page1.section5.content1")}
                                        <a href={t("page1.section5.hyperLink2")} target="blank">{t("page1.section5.content2")}</a>
                                        {t("page1.section5.content3")}
                                        <a href={t("page1.section5.hyperLink4")} target="blank">{t("page1.section5.content4")}</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>
                {/* </div> */}
                <Footer page={1} className={"btm-p1"} enableBack={false} enableContinue={(enableContinue && dobError?.length <= 0 && procError?.length <= 0 && Object.keys(errorForm).length <= 0 ? true : false)} />
            </form>
        </>
    );
};

export default Section5;