import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { getMaxDate, getMinDate } from "../../../utils/functions";

const DateInput = forwardRef(({ id, className, placeholder, error, value, onTextChange, ...props }, ref) => {

    return (
        <>
            <div className="section__input-date container mt-1 d-flex justify-content-center">
                <input id={id} ref={ref} className={cn(className, { "invalid": error !== '' })} type="date"
                    placeholder={placeholder}
                    onKeyDown={(evt) => evt.preventDefault()} {...props}
                    onChange={onTextChange}
                    min={getMinDate()}
                    max={getMaxDate()}
                    value={value}
                />
            </div>
            {
                error !== "" && (
                    <span
                        style={{ display: error !== '' ? "block" : "none" }}
                        className="error-message text-center pt-2">{error}</span>
                )
            }
        </>
    );
});

DateInput.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    onTextChange: PropTypes.func
};

export default DateInput;