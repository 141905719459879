import React, { useEffect, useState } from 'react';

const TimerCount = ({ timerCount, setInitialTime }) => {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    //  console.log("timerCount", timerCount);
    useEffect(() => {
        if (timerCount > 0) {
            setTimeout(() => {
                setInitialTime(timerCount - 1);
                setMinutes(Math.floor((timerCount / 60) % 60));
                setSeconds(Math.floor(timerCount % 60));
            }, 1000);
        } else {
            setInitialTime(0);
            setMinutes(0);
            setSeconds(0);
        }
    }, [setInitialTime, timerCount]);

    return (
        <>
            {timerCount !== 0 ? (<div className={"countdown"}>
                {minutes >= 10 ? `${minutes}` : `0${minutes}`}:{seconds >= 10 ? `${seconds}` : `0${seconds}`} s
            </div>) : null}
        </>
    );
};

export default TimerCount;