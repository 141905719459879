import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useController } from "react-hook-form";
import { formatCurrency, INPUT_DATA_TYPE, formatPercent } from "../../../utils";

const Input = ({ className, placeholder, control, name, error, dataType, type, ...props }) => {
    const {
        field
    } = useController({
        name,
        control
    });

    const [value, setValue] = useState(String(field.value));

    useEffect(() => {
        setValue(String(field.value));
    }, [field.value, setValue]);

    return (
        <input
            className={cn(className, { "invalid": error })}
            type={type}
            name={field.name}
            onBlur={field.onBlur}
            value={value}
            ref={field.ref}
            placeholder={placeholder}
            onChange={(e) => {
                if (INPUT_DATA_TYPE.CURRENCY === dataType) {
                    let formattedValue = formatCurrency(e.target.value);
                    setValue(formattedValue);
                    field.onChange(formattedValue);
                } else {
                    field.onChange(e.target.value);
                    setValue(e.target.value);
                }
            }}
            {...props}
        />
    )
};

Input.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    dataType: PropTypes.string,
    type: PropTypes.string
}
Input.defaultProps = {
    type: "text"
}


export default Input;