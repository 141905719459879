import {useMediaQuery} from 'react-responsive';

export const useResponsive = () => {
    const isMobile = useMediaQuery({
        maxWidth: 540,
    });

    const isTablet = useMediaQuery({
        minWidth: 541,
        maxWidth: 990
    });

    const isDesktop = useMediaQuery({minWidth: 991});

    return {isMobile, isTablet, isDesktop};
};