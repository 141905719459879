import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import cn from "classnames";

const NoteIcon = ({contentList, isHidden, setIsHidden, error,classNameDivOut,classNameNote,idButton,target}) => {
    const {t} = useTranslation();

    const showPopupContent = () => {
        if (isHidden) {
            setIsHidden(false);
        }
    };

    return(
        <div className={classNameDivOut}>
            <div className={cn(classNameNote, {"invalid-faq-icon": error})} id={idButton} data-target={target} onClick={showPopupContent}>{t("questionMark")}</div>
            <div className="popup" id={target} style={{display: isHidden ? "none" : "block"}}>
                <div className="wrapper-popup">
                    {contentList && contentList.map((item) => (<li key={item.id}>{item.value}</li>))}
                </div>
            </div>
        </div>
    );
};

NoteIcon.propTypes = {
    contentList: PropTypes.array
}

export default NoteIcon;