import React from 'react';
import { checkValueTableResult } from '../../../utils/functions';

const TableResult = ({ projectSelection }) => {

    const columResult = projectSelection?.map(function (item, index) {
        return (
            <tr key={index}>
                <td className="tg-lboi">{item?.ageOfLifeAssuredByPolicyYear + "/" + item?.policyYear}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.accumulatedPremium)}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.allocationCharge)}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.allocatedPremium)}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.coi)}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.diabetesBenefit)}</td>
                <td className="tg-0pky">{checkValueTableResult(item?.earlyStageCancerBenefit)}</td>
                <td className="tg-0pky">{checkValueTableResult(item?.lateStageCancerBenefit)}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.deathTpdBenefit)}</td>
                <td className="tg-lboi">{checkValueTableResult(item?.accountValue)}</td>
                <td className="tg-0pky">{checkValueTableResult(item?.surrenderValue)}</td>
            </tr>
        )
    })
    return (
        <>
            {columResult}
        </>
    );
};
export default TableResult;