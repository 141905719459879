import { createSlice } from "@reduxjs/toolkit";
import { getConfig, getOTP, logOut, resetErrorPopupAuth, verifyOTP } from "./authAction";

const initialState = {
    loading: false,
    isPopup: true,
    isErrorNotfound: false,
    numberSendOtp: 0,
    isExistSession: false,
    isSendOTP: false,
    isVerifyOtp: null,
    error: null,
    token: null,
    success: false,
    isLockSendOtp: false,
    isReadOnly: false,
    isUnAuthorizedVerify: true,

    inforPage1: null,
    getOTPRes: null,
    verifyOtpRes: null,
    objectTimeOut:null,


    timerCountDownSendOtp: 0,

    timerCountDownVerifyOtp: 0
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOTP.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getOTP.fulfilled, (state, action) => {
                state.success = true;
                state.loading = false;
                state.isErrorNotfound = false;
                if (action.payload.response?.status === 404 || action.payload.response?.status === 500 ) 
                {
                    const errorNotfound = {
                        code:"500"
                    }
                    state.getOTPRes = errorNotfound;
                    state.isErrorNotfound = true;
                    state.numberSendOtp = state.numberSendOtp + 1;
                }
                else {
                    if (action.payload !== undefined) {
                        state.getOTPRes = action.payload;
                        state.isSendOTP = false;
                        state.numberSendOtp = state.numberSendOtp + 1;
                        if (action.payload.code === "200") {
                            localStorage.setItem("noOfTab", "1");
                            sessionStorage.setItem("tabs", "1");
                            state.isSendOTP = true;
                            state.isLockSendOtp = false;
                            state.inforPage1 = action.payload.inforPage1;
                            state.timerCountDownSendOtp = parseInt(action.payload.data / 1000);
                            state.verifyOtpRes = null;
                            state.isVerifyOtp = false;
                            state.isReadOnly = false;
                            state.timerCountDownVerifyOtp = 0;
                            state.isExistSession = false;
                        }
                        else {
                            if (action.payload.code === "009") {
                                state.isSendOTP = true;
                                state.isExistSession = true;
                                state.inforPage1 = action.payload.inforPage1;
                            } else if (action.payload.code === "001" || action.payload.code === "0012") {
                                state.isLockSendOtp = true;
                                state.timerCountDownSendOtp = parseInt(action.payload.data / 1000);
                            }
                            state.numberSendOtp = state.numberSendOtp + 1;
                        }
                    } else {
                        state.isPopup = false;
                    }
                }
            })
            .addCase(getOTP.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(verifyOTP.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.success = true;
                state.loading = false;
                if (action.payload.response?.status === 401) {
                    state.isUnAuthorizedVerify = false;
                } else if (action.payload.response !== undefined && action.payload.response?.status !== 401) {
                    state.isPopup = false;
                }
                else {
                    if (action.payload !== undefined) {
                        state.verifyOtpRes = action.payload;
                        if (action.payload.code === "000") {
                            state.timerCountDownVerifyOtp = 0;
                            state.isReadOnly = false;
                            state.getOTPRes = null;
                            state.isVerifyOtp = true;
                            state.isExistSession = true;
                            state.token = action.payload.data;
                            state.inforPage1 = action.payload.inforPage1;
                        }
                        else {
                            state.isVerifyOtp = false;
                            if (action.payload.code === "004") {
                                state.isReadOnly = true;
                                state.timerCountDownSendOtp = 0;
                                state.timerCountDownVerifyOtp = parseInt(action.payload.data / 1000);
                            }
                        }
                    } else {
                        state.isPopup = false;
                    }
                }
            })
            .addCase(verifyOTP.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })
            .addCase(getConfig.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getConfig.fulfilled, (state, action) => {
                state.success = true;
                state.loading = false;
                const objectTimeOut ={
                    inactiveTimeOut: 900000,
                    sessionTimeOut: 900000
                }
                state.objectTimeOut = objectTimeOut;
                if (action.payload.response?.status) {
                    state.isPopup = false;
                }
                else {
                    if (action.payload !== undefined) {
                        if (action.payload.code === "000") {
                            if (action.payload.data?.inactiveTimeOut > 0 && action.payload.data?.sessionTimeOut > 0)
                            {
                                state.objectTimeOut = action.payload.data;
                            }else
                            {
                                state.objectTimeOut = objectTimeOut;
                            }
                        }
                    } else {
                        state.isPopup = false;
                    }
                }

            })
            .addCase(getConfig.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                if (!!payload.status) {
                    state.isPopup = false;
                }
            })
            .addCase(resetErrorPopupAuth, (state, action) => {
                state.isPopup = true;
            })
            .addCase(logOut.pending, (state, action) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(logOut.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                if(action.payload.response?.status === 401)
                {
                    state.isUnAuthorizedVerify = false;
                }else if(action.payload.response !== undefined && action.payload.response?.status !== 401)
                {
                    state.isPopup = false;
                }
            })
            .addCase(logOut.rejected, (state, action) => {
                state.loading = false;
                state.success = true;
            })
    }
});

export default authSlice.reducer;