import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import "../node_modules/bootstrap/dist/css/bootstrap.css";
import '../node_modules/flatpickr/dist/flatpickr';
import "../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

import './styles/main.css';
import './styles/custom.css';
import './styles/input_progress.css';

import "./i18n";
import {Provider} from "react-redux";
import store from "./features/store";

import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
          <Provider store={store}>
              <App />
          </Provider>
      </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
