export const getStateFromSessionStorage = (storageKey) => {
    const savedState = !!window.sessionStorage.getItem(storageKey) ? window.sessionStorage.getItem(storageKey) : null;

    try {
        if (!savedState) {
            return undefined;
        }
        return JSON.parse(savedState);
    } catch (e) {
        console.log(`Could not find state ${storageKey}`);
    }
};