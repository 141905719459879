import React from "react";
import {useTranslation} from "react-i18next";
import {Header_Logo} from "../../../assets/images";
import ProgressBar from "./progressBar";

const Header = () => {
    const {t} = useTranslation();
    return(
        <div className="container-fluid">
            <header className="header">
                <div className="header__logo d-flex justify-content-center">
                    <img src={Header_Logo} alt="BIDV_Metlife_logo"/>
                </div>
                <div className="header__title d-flex justify-content-center ">
                    <h4>{t("header.mainTitle")}</h4>
                </div>
                <ProgressBar />
            </header>
        </div>
    );
};

export default Header;