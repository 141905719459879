import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getStateFromSessionStorage } from "../../utils";
import MainService from "../../services/main";


export const calculateByInsuredAmount = createAsyncThunk("main/calculateByInsuredAmount", async (data) => {
    try {
        const AuthStore = getStateFromSessionStorage("AuthStore");
        const dob = AuthStore.inforPage1?.dateOfBirth;
        const gender = AuthStore.inforPage1?.gender;
        const requestData = {
            insuredAmount: data.insuredAmount,
            dateOfBirth: dob,
            gender: parseInt(gender),
            moneyTransactionValue: "",
            coverageTermPeriod: "",
            paymentTermPeriod: "",
            projectionRate: "",
            type: 1
        };
        const response = await MainService.calculate(requestData);
        return response.data;
    } catch (e) {
        // console.log("Has error when calculateByInsuredAmount", e);
        //  console.log("error", e);
         return e;
    }

});

export const saveInputValuePage2 = createAction("main/saveInputValuePage2", (data) => {
    // console.log("saveInputValuePage2 action", data);
    return {
        payload: {
            data,
        },
    };
});

export const calculateByInterestRate = createAsyncThunk("main/calculateByInterestRate", async (data) => {
    try {
        // console.log("calculateByInterestRate action", data);
        const response = await MainService.calculate(data);
        // console.log("calculateByInterestRate response", response);
        return response.data;
    } catch (e) {
        // console.log("Has error when calculateByInterestRate", e.getMessage);
        return e;
    }
});

export const resetBackToPage2 = createAction("main/resetBackToPage2", (data) => {
    // console.log("resetBackToPage2 action", data);
    return {
        payload: {
            data,
        },
    };
});

export const resetBackToPage1 = createAction("main/resetBackToPage1", (data) => {
    // console.log("resetBackToPage1 action", data);
    return {
        payload: {
            data,
        },
    };
});
export const resetGotoPage1 = createAction("main/resetGotoPage1", (data) => {
    // console.log("resetGotoPage1 action", data);
    return {
        payload: {
            data,
        },
    };
});
