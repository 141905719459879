import React from 'react';
import {
    Additional_Fee,
    Contract_Term,
    Corresponding_Basic_Fee,
    Estimated_Fee_Payment,
    Insurance_Money
} from "../../../assets/icons";
import {
    formatCurrency,
    INPUT_DATA_TYPE,
    NOTE_CONTENT,
    revertFormatCurrency
} from "../../../utils";
import NoteIcon from "../../components/icon/noteIcon";
import Input from "../../components/input/input";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import PropType from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from "react-redux";
import { getPolicyTermDefault } from "../../../utils/functions";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { saveInputValuePage2, resetBackToPage1 } from "../../../features/main/mainAction";
import { URL_PAGE } from '../../../utils/constant';

const Section2 = ({
    isHiddenInsuranceAmountNote,
    setIsHiddenInsuranceAmountNote,
    isHiddenMoneyTransactionValueNote,
    setIsHiddenMoneyTransactionValueNote,
    isHiddenPolicyTermNote,
    setIsHiddenPolicyTermNote,
    isHiddenEstimateFeePaymentNote,
    setIsHiddenEstimateFeePayment,
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        insuredAmount,
        basedPremium,
        dateOfBirth,
        moneyTransactionValue,
        estimateFeePayment,
        policyTerm
    } = useSelector(
        (state) => state.main
    );
    const navigate = useNavigate();

    const schemaSection2 = yup.object().shape({
        moneyTransactionValue: yup.string().notRequired().when(['moneyTransactionValue'], {
            is: (value) => {
                return !!value && value !== "";
            },
            then: () => yup.string().test('isNumber', 'Must be a valid number', (value) => !isNaN(revertFormatCurrency(value)))
                .test("test-min", "Less than 0", (val) => {
                    return parseInt(revertFormatCurrency(val)) >= 100000
                })
                .test("test-div-1000", "Not div 1000", (val) => {
                    return (parseInt(revertFormatCurrency(val)) % 1000 === 0)
                })
                .test("test-max", "Great than max number", (val) => {
                    return (parseInt(revertFormatCurrency(val)) <= (parseInt(revertFormatCurrency(basedPremium)) * 5))
                })
        }),
        policyTerm: yup.string().required()
            .matches(/^\d+$/)
            .test("isNumber", "Must be a valid number", (value) => !isNaN(parseInt(value)))
            .test("test-min", "Less than 11", (value) => parseInt(value) >= 11)
            .test("test-max", "Great than default", (value) => parseInt(value) <= getPolicyTermDefault(dateOfBirth)),
        estimateFeePayment: yup.string().required()
            .matches(/^\d+$/)
            .test("isNumber", "Must be a valid number", (value) => !isNaN(parseInt(value)))
            .test("test-min", "Less than 3", (value) => parseInt(value) >= 3)
            .test({
                name: "test-max",
                exclusive: false,
                params: {},
                message: 'Great than policyTerm',
                test: function (value) {
                    return value <= parseInt(this.parent.policyTerm)
                }
            })
    },
        ['moneyTransactionValue', 'moneyTransactionValue']
    );

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            "insuredAmount": '' !== insuredAmount ? formatCurrency(insuredAmount) : '',
            "basedPremium": formatCurrency(basedPremium),
            "moneyTransactionValue": !!moneyTransactionValue && revertFormatCurrency(moneyTransactionValue) > 0 ? moneyTransactionValue : "",
            "policyTerm": !!policyTerm ? policyTerm : getPolicyTermDefault(dateOfBirth),
            "estimateFeePayment": !!estimateFeePayment ? estimateFeePayment : getPolicyTermDefault(dateOfBirth)
        },
        reValidateMode: "onChange",
        resolver: yupResolver(schemaSection2)
    });

    //  console.log(errors)
    const onSection2Submit = (data) => {
        if ("" === data.moneyTransactionValue) {
            data.moneyTransactionValue = "0";
        }
        dispatch(saveInputValuePage2(data));
        sessionStorage.setItem("pages", "3");
        navigate(URL_PAGE.PAGE_3);
    };

    const handleBackPage = () => {
        dispatch(resetBackToPage1());
        sessionStorage.setItem("pages", "1");
        navigate(URL_PAGE.PAGE_1);
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSection2Submit)}>
                <section className="section__value ">
                    <div className="value-1 row justify-content-center pb-4">
                        <div className="row col-md-6 col-xl-5">
                            <div className="col-2"><img src={Insurance_Money} alt={t("page2.section1.title1")} /></div>
                            <div className="col-10">
                                <h2 className="function__title ">{t("page2.section1.title1")}</h2>
                            </div>
                        </div>
                        <div className="row col-md-6 col-xl-5 pt-1 justify-content-center">
                            <div className="col-2 unit pr-5 pt-2 ">{t("currencyUnit")}</div>
                            <div className="col-8 number_money form-outline">
                                <div className="number-input">
                                    <Input
                                        className={"form-control number-money-input"}
                                        placeholder={"Số tiền"}
                                        control={control}
                                        name={"insuredAmount"}
                                        error={!!errors.insuredAmount?.message}
                                        dataType={INPUT_DATA_TYPE.CURRENCY}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <NoteIcon
                                classNameDivOut={"col-1 pt-1 faq-icon"}
                                classNameNote={"faq-button"}
                                idButton={"faq-button"}
                                target={"popup2"}
                                contentList={NOTE_CONTENT.insuranceAmountNoteContent}
                                isHidden={isHiddenInsuranceAmountNote}
                                setIsHidden={setIsHiddenInsuranceAmountNote}
                                error={!!errors.insuredAmount?.message}
                            />
                        </div>
                    </div>
                    {/*Phí cơ bản tương ứng: (phí thường niên)*/}
                    <div className="value-2 row justify-content-center pb-4">
                        <div className="row col-md-6 col-xl-5">
                            <div className="col-2"><img src={Corresponding_Basic_Fee} alt={t("page2.section2.title1")} />
                            </div>
                            <div className="col-10">
                                <h2 className="function__title ">{t("page2.section2.title1")}</h2>
                                <p style={{ fontSize: "18px", fontWeight: "600" }}>{t("page2.section2.subTitle1")}</p>
                            </div>
                        </div>
                        <div className="row col-md-6 col-xl-5 pt-1 justify-content-center">
                            <div className=" col-2 unit pr-5 pt-2 ">{t("currencyUnit")}</div>
                            <div className=" col-8 number_money form-outline">
                                <div className="number-input">
                                    <Input
                                        className={"form-control number-money-input"}
                                        placeholder={"Số tiền"}
                                        control={control}
                                        name={"basedPremium"}
                                        error={!!errors.basedPremium?.message}
                                        dataType={INPUT_DATA_TYPE.CURRENCY}
                                        id="amount-entered"
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="col-1 pt-2 faq-icon">
                            </div>
                        </div>
                    </div>
                    {/*Phí đóng thêm*/}
                    <div className="value-3 row justify-content-center pb-4">
                        <div className="row col-md-6 col-xl-5">
                            <div className="col-2"><img src={Additional_Fee} alt={t("page2.section2.title2")} /></div>
                            <div className="col-10">
                                <h2 className="function__title ">{t("page2.section2.title2")}</h2>
                            </div>
                        </div>
                        <div className="row col-md-6 col-xl-5 pt-1 justify-content-center">
                            <div className=" col-2 unit pr-5 pt-2 ">{t("currencyUnit")}</div>
                            <div className=" col-8 number_money form-outline">
                                <div className="number-input">
                                    <Input
                                        className={"form-control number-money-input"}
                                        placeholder={"Số tiền"}
                                        control={control}
                                        name={"moneyTransactionValue"}
                                        error={!!errors.moneyTransactionValue?.message}
                                        dataType={INPUT_DATA_TYPE.CURRENCY}
                                        id="amount-entered-1"
                                    />
                                </div>
                            </div>
                            <NoteIcon
                                classNameDivOut={"col-1 pt-2 faq-icon"}
                                classNameNote={"faq-button faq-button-3 "}
                                idButton={"faq-button"}
                                target={"popup3"}
                                contentList={NOTE_CONTENT.moneyTransactionValueNoteContent}
                                isHidden={isHiddenMoneyTransactionValueNote}
                                setIsHidden={setIsHiddenMoneyTransactionValueNote}
                                error={!!errors.moneyTransactionValue?.message}
                            />
                        </div>
                    </div>
                    {/*Thời hạn hợp đồng*/}
                    <div className="value-4 row justify-content-center pb-4">
                        <div className="row col-md-6 col-xl-5">
                            <div className="col-2"><img src={Contract_Term} alt={t("page2.section2.title3")} /></div>
                            <div className="col-10">
                                <h2 className="function__title ">{t("page2.section2.title3")}</h2>
                            </div>
                        </div>
                        <div className="row col-md-6 col-xl-5 pt-1 justify-content-center">
                            <div className=" col-2 unit pr-5 pt-2 "></div>
                            <div className=" col-8 number_money form-outline">
                                <div className="number-input">
                                    <Input
                                        className={"form-control number-money-input"}
                                        placeholder={"Năm"}
                                        control={control}
                                        name={"policyTerm"}
                                        error={!!errors.policyTerm?.message}
                                        dataType={""}
                                        id="input-year-1"
                                    />
                                </div>
                            </div>
                            <NoteIcon
                                classNameDivOut={"col-1 pt-2 faq-icon"}
                                classNameNote={"faq-button faq-button-4"}
                                idButton={"faq-button"}
                                target={"popup4"}
                                contentList={NOTE_CONTENT.policyTermNoteContent}
                                isHidden={isHiddenPolicyTermNote}
                                setIsHidden={setIsHiddenPolicyTermNote}
                                error={!!errors.policyTerm?.message}
                            />
                        </div>
                    </div>
                    {/*Thời hạn đóng phí dự kiến*/}
                    <div className="value-5 row justify-content-center pb-4">
                        <div className="row col-md-6 col-xl-5">
                            <div className="col-2"><img src={Estimated_Fee_Payment} alt={t("page2.section2.title4")} />
                            </div>
                            <div className="col-10">
                                <h2 className="function__title ">{t("page2.section2.title4")}</h2>
                            </div>
                        </div>
                        <div className="row col-md-6 col-xl-5 pt-1 justify-content-center">
                            <div className=" col-2 unit pr-5 pt-2 "></div>
                            <div className=" col-8 number_money form-outline">
                                <div className="number-input">
                                    <Input
                                        className={"form-control number-money-input"}
                                        placeholder={"Năm"}
                                        control={control}
                                        name={"estimateFeePayment"}
                                        error={!!errors.estimateFeePayment?.message}
                                        dataType={""}
                                        id="input-year-2"
                                    />
                                </div>
                            </div>
                            <NoteIcon
                                classNameDivOut={"col-1 pt-2 faq-icon"}
                                classNameNote={"faq-button faq-button-5"}
                                idButton={"faq-button faq-button-last"}
                                target={"popup5"}
                                contentList={NOTE_CONTENT.estimateFeePaymentNoteContent}
                                isHidden={isHiddenEstimateFeePaymentNote}
                                setIsHidden={setIsHiddenEstimateFeePayment}
                                error={!!errors.estimateFeePayment?.message}
                            />
                        </div>
                    </div>
                </section>
                <Footer page={2} className={"fixed-bottom"} continueBtnType={"submit"}
                    enableContinue={"{}" === JSON.stringify(errors)} onclickBackProcess={handleBackPage} />
            </form>
        </>
    );
};

Section2.prototype = {
    visibleSection2: PropType.bool,
    insuredAmount: PropType.string,
    ageMin: PropType.string,
    isHiddenInsuranceAmountNote: PropType.bool,
    setIsHiddenInsuranceAmountNote: PropType.func,
    isHiddenMoneyTransactionValueNote: PropType.bool,
    setIsHiddenMoneyTransactionValueNote: PropType.func,
    isHiddenPolicyTermNote: PropType.bool,
    setIsHiddenPolicyTermNote: PropType.func,
    isHiddenEstimateFeePaymentNote: PropType.bool,
    setIsHiddenEstimateFeePayment: PropType.func,
    showOffNotePopup: PropType.func
}

export default Section2;