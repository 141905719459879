import React from "react";
import { Insurance_Money } from "../../../assets/icons";
import Input from "../../components/input/input";
import { INPUT_DATA_TYPE, NOTE_CONTENT, formatCurrency, revertFormatCurrency } from "../../../utils";
import NoteIcon from "../../components/icon/noteIcon";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { calculateByInsuredAmount } from "../../../features/main/mainAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/footer";
import { useNavigate } from "react-router-dom";
import { URL_PAGE } from "../../../utils/constant";

const Section1 = ({ isHiddenInsuranceAmountNote, setIsHiddenInsuranceAmountNote }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { insuredAmount } = useSelector((state) => state.main);
    const schema = yup.object().shape({
        insuredAmount: yup.string()
            .test(
                'isNumber',
                'Must be a valid number',
                (value) => !isNaN(revertFormatCurrency(value))
            )
            .test("test-min", "Min err", (value) => parseInt(revertFormatCurrency(value)) >= 200000000)
            .test("test-max", "Max err", (value) => parseInt(revertFormatCurrency(value)) <= 20000000000)
            .test("test-divide", "Divide 1000", (value) => {
                return parseInt(revertFormatCurrency(value)) % 1000000 === 0
            })
            .required()
    });
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            "insuredAmount": !!insuredAmount ? formatCurrency(insuredAmount) : "",
        },
        resolver: yupResolver(schema),
        reValidateMode: "onChange"
    });
    const onSubmit = (data) => {
        data.insuredAmount = revertFormatCurrency(data.insuredAmount);
        dispatch(calculateByInsuredAmount(data));
    };

    const handleBackPage = () => {
        sessionStorage.setItem("pages", "1");
        navigate(URL_PAGE.PAGE_1);
    };
    return (
        <>
            <section className="section__insurance-money">
                <div className="row justify-content-center">
                    <div className="row col-md-6 col-xl-5">
                        <div className="col-2"><img src={Insurance_Money} alt={t("page2.section1.title1")} />
                        </div>
                        <div className="col-10">
                            <h2 className="function__title ">{t("page2.section1.title1")}</h2>
                        </div>
                    </div>
                    <div className="row col-md-6 col-xl-5 pt-1 justify-content-center">
                        <div className="col-2 unit pr-5 pt-2 ">{t("currencyUnit")}</div>
                        <div className="col-8 number_money form-outline">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="number-input">
                                    <Input
                                        className={"form-control number-money-input"}
                                        placeholder={"Số tiền"}
                                        control={control}
                                        name={"insuredAmount"}
                                        error={!!errors.insuredAmount?.message}
                                        dataType={INPUT_DATA_TYPE.CURRENCY}
                                    />
                                </div>
                                <div className="py-3"></div>
                                <div className="calculate-icon">
                                    <button type={"submit"} className="calculate btn">TÍNH TOÁN</button>
                                </div>
                            </form>

                        </div>
                        <NoteIcon
                            classNameDivOut={"col-1 pt-2 faq-icon"}
                            classNameNote={"faq-button"}
                            idButton={"faq-button faq-button-first"}
                            target={"popup1"}
                            contentList={NOTE_CONTENT.insuranceAmountNoteContent}
                            isHidden={isHiddenInsuranceAmountNote}
                            setIsHidden={setIsHiddenInsuranceAmountNote}
                            error={!!errors.insuredAmount?.message}
                        />
                    </div>
                </div>
            </section>
            <Footer page={2} className={"fixed-bottom"} enableContinue={false} onclickBackProcess={handleBackPage} />
        </>


    );
};

export default Section1;