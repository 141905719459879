import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../button/button";
import Proptypes from 'prop-types';
import cn from "classnames";
import { useResponsive } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { URL_PAGE } from "../../../utils/constant";
import { logOut } from "../../../features/auth/authAction";

const Footer = ({ page, enableBack, enableContinue, isEndProcess, className, continueBtnType, onClickEndProcess, onclickBackProcess }) => {
    const { token
    } = useSelector((state) => state.auth);
    const numbnerPage = sessionStorage.getItem("pages");
    const { t } = useTranslation();
    const {isMobile} = useResponsive();
    const dispatch = useDispatch();
    const endProcess = () => {
        if (!!token)
        {
            dispatch(logOut()).then((res) => {
                if (res.payload.code === "000") {
                    window.location.replace(URL_PAGE.HOME_BIDV);
                }
            });
        }else
        {
            window.location.replace(URL_PAGE.HOME_BIDV);
        }
        
        
    }

    return (
        <footer className={cn("footer", className)}>
            <div className="container">
                <section className="section_footer">
                    <div className="row d-flex justify-content-between py-2 ">
                        {/* Page 1 */}
                        <div className={!enableBack ? "col-2 col-lg-3 col-md-4 col-sm-5" : "col-5 col-lg-3 col-md-4 col-sm-5"}>
                            <Button btnClassName={"back-progress btn"}
                                btnTxtContent={t("footer.previousButtonTxt")} onClick={onclickBackProcess} style={{ display: enableBack ? "block" : "none" }}/>
                        </div>

                        {/* Page 2 3 */}
                    {(!isMobile || numbnerPage === "0"|| numbnerPage === "1") ? <div className={enableBack?"col-2 col-lg-3 col-md-4 col-sm-2":"col-5 col-lg-3 col-md-4 col-sm-5"}>
                        <Button btnClassName={"logout-process btn"} btnType={"button"}
                        onClick={endProcess}
                            btnTxtContent={t("footer.endButtonTxt")} style={{ display: enableBack &&  isEndProcess  ? "none" : "block" }}>
                        </Button>
                    </div> : null}
                        
                        <div className={enableContinue ? "col-5 col-lg-3 col-md-4 col-sm-5 continue-progress-page1" : "col-5 col-lg-3 col-md-4 col-sm-5 continue-progress-page1 disabled"}>
                            <Button btnClassName={"continue-progress continue-progress-1 btn"}
                                btnTxtContent={isEndProcess ? t("footer.endButtonTxt") : t("footer.nextButtonTxt")} 
                                btnType={continueBtnType} 
                                onClick={isEndProcess ? endProcess : null}
                                style={{background:isEndProcess?"#007ABC":null}}
                                >
                            </Button>
                        </div>
                        {/* Mobile */}
                        {isMobile && numbnerPage !== "0" && numbnerPage !== "1" ? 
                        <div className="col-12" style={{ display: (enableBack && isEndProcess)? "none" : "block", marginTop:"15px"}}>
                        <Button btnClassName={"logout-process btn"}
                                btnType={"button"}
                                onClick={endProcess}
                                btnTxtContent={t("footer.endButtonTxt")} >
                            </Button>
                        </div>:null}
                        <div className="col-12 footer__content--mb">
                            <p className="footer__content">{t("footer.mainTitle")}</p>
                        </div>
                    </div>
                </section>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    enableBack: Proptypes.bool,
    enableContinue: Proptypes.bool,
    className: Proptypes.string,
    continueBtnType: Proptypes.string,
    page: Proptypes.number,
    isEndProcess: Proptypes.bool
}

Footer.defaultProps = {
    enableBack: true,
    enableContinue: false,
    isEndProcess: false,
    page: 0
}

export default Footer;