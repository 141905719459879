import React, { useState } from "react";
import Section2 from "./section2";
import { useDispatch, useSelector } from 'react-redux';
import Section1 from "./section1";
import Popup from "../../components/popup/popup";
import { useTranslation } from "react-i18next";
import { URL_PAGE } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { resetGotoPage1 } from "../../../features/main/mainAction";

const Page2 = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isCalculatedByInsuredAmount, isPopupPage2, isUnAuthorized } = useSelector(
        (state) => state.main
    );
    const [isHiddenInsuranceAmountNote, setIsHiddenInsuranceAmountNote] = useState(true);
    const [isHiddenMoneyTransactionValueNote, setIsHiddenMoneyTransactionValueNote] = useState(true);
    const [isHiddenAdditionalFeeNote, setIsHiddenAdditionalFeeNote] = useState(true);
    const [isHiddenPolicyTermNote, setIsHiddenPolicyTermNote] = useState(true);
    const [isHiddenEstimateFeePaymentNote, setIsHiddenEstimateFeePayment] = useState(true);

    const showOffNotePopup = () => {
        if (!isHiddenInsuranceAmountNote) {
            setIsHiddenInsuranceAmountNote(true);
        }
        if (!isHiddenMoneyTransactionValueNote) {
            setIsHiddenMoneyTransactionValueNote(true);
        }
        if (!isHiddenAdditionalFeeNote) {
            setIsHiddenAdditionalFeeNote(true);
        }
        if (!isHiddenPolicyTermNote) {
            setIsHiddenPolicyTermNote(true);
        }
        if (!isHiddenEstimateFeePaymentNote) {
            setIsHiddenEstimateFeePayment(true);
        }
    };
    const onClosePopUp = () => {
        if(!isUnAuthorized)
        {
            window.location.href = URL_PAGE.RELOAD_PAGE_1
        }else
        {
            dispatch(resetGotoPage1());
            sessionStorage.setItem("pages", "1");
            navigate(URL_PAGE.PAGE_1);
        }
        
    }

    return (
        <>
            <Popup hidden={isUnAuthorized} header={t("popUp.header")} content={t("popUp.contentUnAuthorized")} onClose={onClosePopUp} />
            <Popup hidden={isPopupPage2} header={t("popUp.header")} content={t("popUp.contentErrorSystem")} onClose={onClosePopUp} />
            <div className={"container content"} onClick={showOffNotePopup}>
                {!isCalculatedByInsuredAmount ? (
                    <Section1
                        isHiddenInsuranceAmountNote={isHiddenInsuranceAmountNote}
                        setIsHiddenInsuranceAmountNote={setIsHiddenInsuranceAmountNote}
                    />
                ) : (
                    <Section2
                        isHiddenInsuranceAmountNote={isHiddenInsuranceAmountNote}
                        setIsHiddenInsuranceAmountNote={setIsHiddenInsuranceAmountNote}


                        isHiddenMoneyTransactionValueNote={isHiddenMoneyTransactionValueNote}
                        setIsHiddenMoneyTransactionValueNote={setIsHiddenMoneyTransactionValueNote}



                        isHiddenPolicyTermNote={isHiddenPolicyTermNote}
                        setIsHiddenPolicyTermNote={setIsHiddenPolicyTermNote}


                        isHiddenEstimateFeePaymentNote={isHiddenEstimateFeePaymentNote}
                        setIsHiddenEstimateFeePayment={setIsHiddenEstimateFeePayment}
                        showOffNotePopup={showOffNotePopup}
                    />
                )}
            </div>
        </>
    )
        ;
};

export default Page2;