export const calculateYearsAge = (dob) => {
    // convert to Date obj
    let dateOfBirthVal = new Date(dob);
    let today = new Date();
    // calculate age
    let age = today.getFullYear() - dateOfBirthVal.getFullYear();
    // calculate month difference
    let monthDifference = today.getMonth() - dateOfBirthVal.getMonth();
    // check month difference and date difference to re-calculate age
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dateOfBirthVal.getDate())) {
        age--;
    }
    return age;
};

export const calculateDaysAge = (dob) => {
    let dateOfBirthVal = new Date(dob);
    let today = new Date();
    let monthDifference = today.getMonth() - dateOfBirthVal.getMonth();
    if (monthDifference === 0) {
        return today.getDate() - dateOfBirthVal.getDate();
    } else {
        let start = Date.parse(today.toISOString().split("T")[0]);
        let end = Date.parse(dob);
        let timesDiff = start - end;
        return Math.floor(timesDiff / (1000 * 60 * 60 * 24));
    }
};

export const getMinDate = () => {
    let today = new Date();
    let minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 100);
    return minDate.toISOString().split("T")[0];
};

export const getMaxDate = () => {
    let today = new Date();
    return today.toISOString().split("T")[0];
};

export const formatCurrency = (value) => {
    let inputVal = value.replace(/,/g, '');
    return inputVal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPercent = (value) => {
    return value.concat(".0");
};

export const revertFormatCurrency = (value) => {
    return value.replace(/,/g, '');
};

export const getPolicyTermDefault = (dob) => {
    if ("" === dob) return -1;
    const age = calculateYearsAge(dob);
    return Math.min(80 - age, 30);
};

export const checkValueTableResult = (value) => {
    if (value === undefined || value === null || "" === value || "0" === value) {
        return "-";
    }
    return formatCurrency(value);
}

