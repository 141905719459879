import React, { memo, useEffect } from "react";
import Header from "./components/header/header";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Page1 from "./pages/page1/page1";
import Page2 from "./pages/page2/page2";
import Page3 from "./pages/page3/page3";
import { STORAGE_KEYS } from "../utils";
import { URL_PAGE } from "../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import OtherPage from "./pages/page1/otherPage";
import { getConfig } from "../features/auth/authAction";

const ProtectedLayout = () => {
    const noOfTab = localStorage.getItem("noOfTab");
    const dispatch = useDispatch();
    const { token, objectTimeOut } = useSelector(
        (state) => state.auth
    );
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            localStorage.setItem("noOfTab", "0");
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (!token) {
            navigate(URL_PAGE.PAGE_1);
        }
        if (noOfTab === "1") {
            navigate(URL_PAGE.OTHER_PAGE);
        }
        sessionStorage.setItem("pages", "0");
        // localStorage.setItem("tabs", "0");
        sessionStorage.setItem(STORAGE_KEYS.AUTH_STORAGE, JSON.stringify(null));
        sessionStorage.setItem(STORAGE_KEYS.MAIN_STORAGE, JSON.stringify(null));
        dispatch(getConfig());
    }, []);

    const handleOnIdle = () => {
        window.location.replace(URL_PAGE.TIME_OUT_URL);
    };

    const { getRemainingTime, reset } = useIdleTimer({
        timeout: objectTimeOut?.inactiveTimeOut,
        onIdle: handleOnIdle,
        debounce: 500,
    });



    return (
        <div>
            <Header />
            <div className={"container content content-p1"} version={"UAT: 2023/11/15 15:00 | PROD: 2023/11/18 12:00"}>
                <Routes>
                    <Route path={URL_PAGE.PAGE_1} element={<Page1 />} />
                    <Route path={URL_PAGE.PAGE_2} element={<Page2 />} />
                    <Route path={URL_PAGE.PAGE_3} element={<Page3 />} />
                    <Route path={URL_PAGE.OTHER_PAGE} element={<OtherPage />} />
                    <Route path={"/"} element={<Navigate to={URL_PAGE.PAGE_1} />} />
                </Routes>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export default memo(ProtectedLayout);