import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import mainReducer from "./main/mainSlice";
import { STORAGE_KEYS } from "../utils";

const reducer = {
    auth: authReducer,
    main: mainReducer
};

const authMiddleware = (store) => (next) => (action) => {
    const result = next(action);
    if (action.type?.startsWith("auth/")) {
        //console.log("store", store);
        const authState = store.getState().auth;
        //console.log("store",JSON.stringify(authState))
        sessionStorage.setItem(STORAGE_KEYS.AUTH_STORAGE, JSON.stringify(authState))
    }
    return result;
};

const mainMiddleware = (store) => (next) => (action) => {
    const result = next(action);
    if (action.type?.startsWith("main/")) {
        //console.log("store", store);
        const mainState = store.getState().main;
        //console.log("store",JSON.stringify(authState))
        sessionStorage.setItem(STORAGE_KEYS.MAIN_STORAGE, JSON.stringify(mainState))
    }
    return result;
};

const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware).concat(mainMiddleware)
})

export default store;