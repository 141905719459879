import axios from "axios";
import { getStateFromSessionStorage, STORAGE_KEYS } from "../utils";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: process.env.REACT_APP_TIMEOUT,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':
            'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token, X-Requested-With, Accept',
        'Content-Type': 'application/json',
        crossDomain: true,
    }
});

const onRequestSuccess = async (config) => {
    const { token } = getStateFromSessionStorage(STORAGE_KEYS.AUTH_STORAGE) ?? { token: '' }
    if (token) {
        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            }
        }
    }
    return config;
};

axiosInstance.interceptors.request.use(onRequestSuccess);

const { get, post } = axiosInstance;
export { get, post };