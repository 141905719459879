import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TableResult from "./tableResult";
import cn from "classnames";

const CalculateResult = () => {
    const { t } = useTranslation();
    const [isGuarantee, setIsGuarantee] = useState(false);
    const { projectionGuaranteed, projectionCustom } = useSelector(
        (state) => state.main
    );
    useEffect(() => {
        // console.log("projectionGuaranteed", projectionGuaranteed);
        // console.log("projectionCustom", projectionCustom);
    }, [projectionGuaranteed, projectionCustom]);

    const onClickGuarantee = () => {
        setIsGuarantee(true);
    }
    const onClickCustom = () => {
        setIsGuarantee(false);
    }

    return (
        <section className="section__show_interest_rate px-1" style={{ display: "block", padding: "5px 15px" }}>
            <div className="container mt-5">
                <div className="text-right text-unit">{t("page3.section2.thousandDong")}</div>
                <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li className="nav-item" onClick={onClickCustom}>
                        <a className={cn("nav-link tab-title-3", { "active": !isGuarantee })} id="tab1-tab" data-toggle="tab" href="#tab1"
                            role="tab" aria-controls="tab1"
                            aria-selected={!isGuarantee ? "true" : "false"}>{t("page3.section2.optionalInterestRate")}</a>
                    </li>
                    <li className="nav-item" onClick={onClickGuarantee}>
                        <a className={cn("nav-link tab-title-3", { "active": isGuarantee })} id="tab2-tab" data-toggle="tab" href="#tab2" role="tab"
                            aria-controls="tab2" aria-selected={isGuarantee ? "true" : "false"}>{t("page3.section2.committedInterestRate")}</a>
                    </li>
                </ul>
                <div className="tab-content mt-2 mx-auto" id="myTabContent">
                    <div className="tab-pane fade show active" role={isGuarantee ? "tabpanel" : "tabpanel"} aria-labelledby={isGuarantee ? "tab2-tab" : "tab1-tab"}>
                        <div className="table-interest-rate " style={{ overflowX: "auto" }}>
                            <table>
                                <tbody>
                                    <tr className="header__table">
                                        <th colSpan="1"></th>
                                        <th colSpan="4" className="header__table__title1">{t("page3.section2.fees")}</th>
                                        <th colSpan="6"
                                            className="header__table__title2">{t("page3.section2.protectionRightsInProduct")}</th>
                                    </tr>
                                </tbody>
                                <colgroup>
                                    <col span="1" className="table__group0" style={{ width: "5%" }} />
                                    <col span="1" className="table__group1" />
                                    <col span="1" className="table__group2" />
                                    <col span="1" className="table__group1" />
                                    <col span="1" className="table__group2" />
                                    <col span="1" className="table__group3" />
                                    <col span="1" className="table__group2" />
                                    <col span="1" className="table__group3" />
                                    <col span="1" className="table__group2" />
                                    <col span="1" className="table__group3" />
                                    <col span="1" className="table__group2" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th className="first_title_tab"
                                            style={{ width: "5%" }}>{t("page3.section2.colTitle1")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle2")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle3")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle4")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle5")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle6")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle7")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle8")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle9")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle10")}</th>
                                        <th className="title_table_2">{t("page3.section2.colTitle11")}</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <TableResult projectSelection={isGuarantee ? projectionGuaranteed : projectionCustom} />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="notes pt-5">
                <h6>{t("page3.section2.noteTitle")}</h6>
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                    <li>{t("page3.section2.note1")}</li>
                    <li>{t("page3.section2.note2")}</li>
                    <li>{t("page3.section2.note3")}</li>
                </ul>
            </div>
        </section>
    );
};

export default CalculateResult;