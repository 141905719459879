export const STORAGE_KEYS = Object.freeze({
    AUTH_STORAGE: 'AuthStore',
    MAIN_STORAGE: 'MainStore'
});

export const AUTH_SERVICE_URL = Object.freeze({
    GET_OTP_URL: "/sendOtp",
    VERIFY_OTP: "/verifyOtp",
    CONFIG: "/config",
    LOGOUT:"/logout"
});

export const MAIN_SERVICE_URL = Object.freeze({
    CALCULATE: "/sendCalculate"
});

export const URL_PAGE = Object.freeze({
    PAGE_1: "/page-1",
    PAGE_2: "/page-2",
    PAGE_3: "/page-3",
    HOME_BIDV: "https://www.bidvmetlife.com.vn/",
    OTHER_PAGE: "/others",
    RELOAD_PAGE_1: "/gsp2c/page-1",
    TIME_OUT_URL:"https://www.bidvmetlife.com.vn/vn/"
})

export const PLACE_HOLDER = Object.freeze({
    DATE_INPUT: " DD/MM/YYYY"
});

export const NOTE_CONTENT = Object.freeze({
    insuranceAmountNoteContent: [
        {
            "id": 1,
            "value": "Số tiền bảo hiểm tối thiểu là 200 triệu đồng, tối đa là 20 tỷ đồng."
        },
        {
            "id": 2,
            "value": "Số tiền bảo hiểm phải là bội số của 1.000.000 đồng."
        },
    ],
    moneyTransactionValueNoteContent: [
        {
            "id": 1,
            "value": "Phí đóng thêm mỗi năm tối thiểu là 100.000 đồng, tối đa bằng 5 lần Phí cơ bản tương ứng."
        },
        {
            "id": 2,
            "value": "Phí đóng thêm mỗi năm phải là bội số của 1.000 đồng."
        }
    ],
    policyTermNoteContent: [
        {
            "id": 1,
            "value": "Thời hạn Hợp đồng từ 11 đến 30 năm."
        },
    ],
    estimateFeePaymentNoteContent: [
        {
            "id": 1,
            "value": "Thời hạn đóng phí dự kiến tối thiểu là 3 năm, tối đa bằng Thời hạn Hợp đồng."
        },
    ],
    interestRateNotContent: [
        {
            "id": 1,
            "value": "Lãi suất có thể lựa chọn từ 0.5% đến 6.0%."
        }
    ]
});

export const INPUT_DATA_TYPE = Object.freeze({
    CURRENCY: "currency",
    PERCENT: "percent"
});

export const RESPONSE_CODE = Object.freeze({
    SUCCESS: "000",
});