import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import cn from "classnames";

const ProgressBar = () => {
    const { verifyOtpRes, isVerifyOtp, isExistSession , isUnAuthorizedVerify ,isPopup} = useSelector((state) => state.auth);
    const { isCalculatedByInsuredAmount, isCalculatedByInterestRate ,  isUnAuthorized,isPopupPage2,isPopupPage3} = useSelector((state) => state.main);
    const location = useLocation();

    const [isPage1, setIsPage1] = useState(false);
    const [isPage2, setIsPage2] = useState(false);
    const [isPage3, setIsPage3] = useState(false);
    const [numberPage, setNumberPage] = useState("");
    const [isPopUpError,setIsPopUpError]= useState(false);

    useEffect(()=>{
        if (isUnAuthorizedVerify)
        {
            setIsPopUpError(isUnAuthorizedVerify);
        }else if (isPopup)
        {
            setIsPopUpError(isPopup)
        }else if (isUnAuthorized)
        {
            setIsPopUpError(isUnAuthorized)
        }else if (isPopupPage2)
        {
            setIsPopUpError(isPopupPage2)
        }else if (isPopupPage3)
        {
            setIsPopUpError(isPopupPage3)
        }
    },[isUnAuthorizedVerify,isPopup,isUnAuthorized,isPopupPage2,isPopupPage3])

    useEffect(() => {
        let numbnerPage = sessionStorage.getItem("pages");
        setNumberPage(numbnerPage);
        setIsPage1(false);

        if (numbnerPage === "1" || numbnerPage === "0") {
            setIsPage1(true);
            setIsPage2(false);
            setIsPage3(false);
        }
        setIsPage2(false);
        if ((isVerifyOtp || isExistSession) && numbnerPage === "2") {
            setIsPage2(true);
            setIsPage1(false);
            setIsPage3(false);
        }
        setIsPage3(false);
        if (numbnerPage === "3") {
            setIsPage3(true);
            setIsPage1(false);
            setIsPage2(false);
        }
    }, [location, verifyOtpRes, isCalculatedByInsuredAmount, isVerifyOtp, isExistSession]);
    return (
        <div className="header__progress container pt-4 pb-1">
            <div className="process-wrapper">
                <div id="progress-bar-container">
                    <div id="line">
                        {
                            isPage1 ? <div id="line-progress"></div> :
                                (
                                    isPage2 ? <div id="line-progress-2" style={{ width: isCalculatedByInsuredAmount ? "50%" : "" }}></div>
                                        :
                                        <div id="line-progress-3" style={{ width: isCalculatedByInterestRate ? "100%" : "" }} />
                                )
                        }
                    </div>
                    <ul style={{zIndex:isPopUpError?"1000":""}}>
                        <li className={cn("step step01 ", { "active": isPage1 })}>
                            <div className="step-inner">Thông tin cơ bản</div>
                        </li>
                        <li className={cn("step step02 ", { "active": isPage2 && !isCalculatedByInsuredAmount })} >
                            <div className="step-inner">Nhập số tiền</div>
                        </li>
                        <li className={cn("step step03 ", { "active": isCalculatedByInsuredAmount && numberPage === "2" })}>
                            <div className="step-inner">Chi phí và số năm</div>
                        </li>
                        <li className={cn("step step04 ", { "active": isPage3 })}>
                            <div className="step-inner">Nhập lãi suất</div>
                        </li>
                        <li className={cn("step step05 ", { "active": isPage3 && isCalculatedByInterestRate })}>
                            <div className="step-inner">Kết thúc</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;