import { createSlice } from "@reduxjs/toolkit";
import { calculateByInsuredAmount, calculateByInterestRate, resetBackToPage1, resetBackToPage2, resetGotoPage1, saveInputValuePage2 } from "./mainAction";
import { RESPONSE_CODE } from "../../utils";

const initialState = {
    loading: false,
    isPopupPage2: true,
    isPopupPage3: true,
    insuredAmount: '',
    error: null,
    success: false,
    basedPremium: '',
    dateOfBirth: '',
    gender: 0,
    policyTerm: '',
    estimateFeePayment: '',
    moneyTransactionValue: '',
    isCalculatedByInsuredAmount: false,
    isCalculatedByInterestRate: false,
    calculateByInterestRateResult: [],
    projectionGuaranteed: [],
    projectionCustom: [],
    isErrorCallCalculateRateThan3: false,
    numberCallRate: 0,
    isUnAuthorized: true,
};

const mainSlice = createSlice({
    name: "main",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(calculateByInsuredAmount.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.isCalculatedByInsuredAmount = false;
            })
            .addCase(calculateByInsuredAmount.fulfilled, (state, action) => {
                state.success = true;
                state.loading = false;
                if(action.payload.response?.status === 401)
                {
                    state.isUnAuthorized = false;
                }else if(action.payload.response !== undefined && action.payload.response?.status !== 401)
                {
                    state.isPopupPage2 = false;
                }
                else
                {
                    if (action.payload !== undefined) {
                        const result = action.payload;
                        if (RESPONSE_CODE.SUCCESS === result.code) {
                            state.basedPremium = result.data.basedPremium;
                            state.dateOfBirth = result.data.dateOfBirth;
                            state.gender = result.data.gender;
                            state.insuredAmount = result.data.insuredAmount;
                            state.isCalculatedByInsuredAmount = true;
                        } else {
                            state.error = result.data;
                            state.isCalculatedByInsuredAmount = false;
                        }
                    } else {
                        state.isPopupPage2 = false;
                    }
                }
            })
            .addCase(calculateByInsuredAmount.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.isCalculatedByInsuredAmount = false;
            })
            .addCase(saveInputValuePage2, (state, action) => {
                state.moneyTransactionValue = action.payload.data.moneyTransactionValue;
                state.policyTerm = action.payload.data.policyTerm;
                state.estimateFeePayment = action.payload.data.estimateFeePayment;
            })
            .addCase(calculateByInterestRate.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.isCalculatedByInterestRate = false;
            })
            .addCase(calculateByInterestRate.fulfilled, (state, action) => {
                state.success = true;
                state.loading = false;
                if(action.payload.response?.status === 401)
                {
                    state.isUnAuthorized = false;
                }else if(action.payload.response !== undefined && action.payload.response?.status !== 401)
                {
                    state.isPopupPage3 = false;
                }
                else
                {
                    if (action.payload !== undefined) {
                        const result = action.payload;
                        if (RESPONSE_CODE.SUCCESS === result.code) {
                            state.projectionGuaranteed = result.data.projectionGuaranteed;
                            state.projectionCustom = result.data.projectionCustom;
                            state.isCalculatedByInterestRate = true;
                            state.isErrorCallCalculateRateThan3 = false;
                        } else {
                            if (result.code === "012") {
                                state.isErrorCallCalculateRateThan3 = true;
                                state.numberCallRate = state.numberCallRate + 1;
                            }
                            state.error = result;
                            state.isCalculatedByInterestRate = false;
                        }
                    }
                    else {
                        state.isPopupPage3 = false;
                    }
                }
            })
            .addCase(calculateByInterestRate.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.isCalculatedByInterestRate = false;
            })
            .addCase(resetBackToPage2, (state, action) => {
                state.isCalculatedByInterestRate = false;
                state.isErrorCallCalculateRateThan3 = false;
            })
            .addCase(resetBackToPage1, (state, action) => {
                state.isCalculatedByInsuredAmount = false;
            })
            .addCase(resetGotoPage1, (state, action) => {
                state.isCalculatedByInterestRate = false;
                state.isCalculatedByInsuredAmount = false;
                state.isErrorCallCalculateRateThan3 = false;
                state.isPopupPage2 = true;
                state.isPopupPage3 = true;
            })
    }
});

export default mainSlice.reducer;