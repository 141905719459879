import { get, post } from "./base";
import { AUTH_SERVICE_URL } from "../utils/constant";

const AuthService = {
    getOTP: (param) => post(AUTH_SERVICE_URL.GET_OTP_URL, param),
    verifyOTP: (param) => post(AUTH_SERVICE_URL.VERIFY_OTP, param),
    getConfig: (param) => get(AUTH_SERVICE_URL.CONFIG),
    logOut: () => post(AUTH_SERVICE_URL.LOGOUT)
};

export default AuthService;