import React from 'react';
import CheckBox from '../../components/input/checkBox';
import Footer from '../../components/footer/footer';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URL_PAGE } from '../../../utils/constant';
import { verifyOTP } from '../../../features/auth/authAction';

const Section5NoOtp = ({ isChangeButton, dobError, procError, errorForm, dataPage1, setProcError }) => {
    //console.log("NO OTP")
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { inforPage1, isExistSession } = useSelector((state) => state.auth);
    const { control, handleSubmit } = useForm(
        {
            defaultValues: {
                checkCodition: inforPage1?.checkCodition ? inforPage1?.checkCodition : false
            }
        }
    );
    const enableContinue = useWatch({ control, name: "checkCodition" });

    const onSubmitSection5NoOtp = (data) => {
        if (dataPage1?.product !== "DEFAULT") {
            let datasend = {
                checkCodition: data.checkCodition,
                phoneNumber: inforPage1.phoneNumber,
                otp: data.otp,
                saveInforPage1: {
                    ...inforPage1,
                    dateOfBirth: dataPage1?.dateOfBirth !== inforPage1?.dadateOfBirth ? dataPage1?.dateOfBirth : inforPage1?.dadateOfBirth,
                    gender: dataPage1?.gender !== inforPage1?.gender ? dataPage1?.gender : inforPage1?.gender,
                    checkCodition: data.checkCodition,
                    otp: data.otp,
                }
            }
            if ((isExistSession)) {
                dispatch(verifyOTP(datasend)).then((res) => {
                    if (res.payload.code === "000") {
                        sessionStorage.setItem("pages", "2");
                        navigate(URL_PAGE.PAGE_2);
                    }
                });
            }
        } else {
            setProcError("  ");
        }

    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmitSection5NoOtp)}>
                {/* <div className={"container content content-p1"}> */}
                    {/*  section 5 - cam kết start  */}
                    <section className="section" style={{ display: (!!dobError && "" !== dobError) || !isChangeButton ? "none" : "block" }}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8 pl-5 ">
                                <div className="section__privacy-policy align-items-center">
                                    <CheckBox className={"form-check-input"} name={"checkCodition"} control={control} id={"defaultCheck1"} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">{t("page1.section5.content1")}
                                        <a href={t("page1.section5.hyperLink2")} target="blank">{t("page1.section5.content2")}</a>
                                        {t("page1.section5.content3")}
                                        <a href={t("page1.section5.hyperLink4")} target="blank">{t("page1.section5.content4")}</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </section>
                {/* </div> */}
                <Footer page={1} className={"btm-p1"} enableBack={false} enableContinue={(enableContinue && dobError?.length <= 0 && procError?.length <= 0 && Object.keys(errorForm).length <= 0 ? true : false)} />
            </form>
        </>
    );
};

export default Section5NoOtp;